
import {
  createVNode,
  computed,
  defineComponent,
  onMounted,
  ref,
  reactive,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { message, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import UploadAttachmentDrawer from "../components/UploadAttachmentDrawer.vue";
import { UndefStr } from "../types";
import { useColumns } from "../useColumns";
import {
  getFaqInfo,
  saveDraft,
  getFaqRule,
  getFaqUser,
  publish,
  genFaqId,
  downloadFile,
} from "@/API/faq";

export default defineComponent({
  components: {
    UploadAttachmentDrawer,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let faqId = route.params.faqId;

    const category = ref<UndefStr>(undefined);
    const categoryList = [
      { type: "Technical Support", id: 1 },
      { type: "Business Treatment", id: 2 },
    ];
    const categoryName = computed(() => {
      return categoryList.find((cat: any) => {
        return category.value === cat.id;
      })?.type;
    });
    const question = ref("");
    const answer = ref("");

    const uploadDrawerVisible = ref(false);
    const fileList = ref<any[]>([]);
    const getFiles = (files: any) => {
      fileList.value = [...fileList.value, ...files];
    };

    const isEmpty = () => {
      if (category.value === undefined || question.value === "") {
        message.warning("Please fill in information!");
        return true;
      }
      return false;
    };
    const save = () => {
      const params = {
        id: faqId,
        category: category.value,
        answer: answer.value,
        question: question.value,
      };
      const fileReqDtos = fileList.value.map((file: any) => {
        return {
          documentId: file.id || file.documentId,
          fileName: file.docName || file.fileName,
        };
      });
      return saveDraft({ ...params, fileReqDtos }).then(() => {
        message.success("Save Successfully!");
      });
    };
    const handleSaveDraft = () => {
      if (isEmpty()) return;
      Modal.confirm({
        title: "Are you sure you want to save this FAQ as Draft?",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "Confirm",
        cancelText: "Cancel",
        onOk() {
          save();
        },
      });
    };

    const deleteAttachment = (index: number) => {
      Modal.confirm({
        title: "Are you sure you want to delete this attachment?",
        icon: createVNode(ExclamationCircleOutlined),
        width: 500,
        okText: "Confirm",
        cancelText: "Cancel",
        onOk() {
          fileList.value.splice(index, 1);
        },
      });
    };

    const activeKey = ref("1");
    const ruleColumns = useColumns();
    const ruleList = ref<any[]>([]);

    const rulePagination = reactive({
      total: 0,
      current: 1,
      pageSize: 20,
    });

    const searchRule = (current: number, size: number) => {
      getFaqRule({ faqId, current, size }).then((res: any) => {
        ruleList.value = res.content;
        rulePagination.total = res.totalElements;
      });
    };

    const userColumns: any[] = [
      {
        title: "Login Name",
        dataIndex: "loginName",
        key: "loginName",
      },
      {
        title: "User Name",
        dataIndex: "userName",
        key: "userName",
      },
      {
        title: "User Group",
        dataIndex: "userGroup",
        key: "userGroup",
      },
      {
        title: "Legal Entity",
        dataIndex: "legalEntity",
        key: "legalEntity",
      },
    ];
    const userList = ref<any[]>([]);
    const userPagnation = reactive({
      total: 0,
      current: 1,
      pageSize: 20,
    });

    const searchUser = (current: number, size: number) => {
      getFaqUser({ faqId, current, size }).then((res: any) => {
        userList.value = res.content;
        userPagnation.total = res.totalElements;
      });
    };

    const publishVisible = ref(false);
    const handlePublish = () => {
      if (isEmpty()) return;
      save()
        .then(() => {
          return publish(faqId as string);
        })
        .then(() => {
          message.success("Successfully Published");
          publishVisible.value = false;
          router.push({ name: "FAQ Management" });
        });
    };

    const downloadAttachment = (file: any) => {
      downloadFile(file.documentId || file.id).then((res: any) => {
        window.open(res);
        // const eleLink = document.createElement("a");
        // eleLink.download = file.fileName;
        // eleLink.style.display = "none";
        // eleLink.href = res;
        // document.body.appendChild(eleLink);
        // eleLink.click();
        // document.body.removeChild(eleLink);
      });
    };

    const gotoConfigurate = () => {
      if (isEmpty()) return;
      save().then(() => {
        router.push({
          name: "FAQ Configrate",
          params: {
            faqId,
          },
        });
      });
    };
    const colHeight = ref(200);
    onMounted(() => {
      if (faqId) {
        getFaqInfo({ id: faqId }).then((res: any) => {
          answer.value = res.answer;
          category.value = res.category;
          question.value = res.question;
          fileList.value = res.files;
        });
        searchRule(1, rulePagination.pageSize);
        searchUser(1, userPagnation.pageSize);
      } else {
        genFaqId().then((res: any) => {
          faqId = res;
        });
      }
    });

    return {
      category,
      categoryList,
      categoryName,
      question,
      answer,
      fileList,
      uploadDrawerVisible,
      getFiles,
      activeKey,
      ruleColumns,
      ruleList,
      colHeight,
      //calculateHeight,
      rulePagination,
      handleSaveDraft,
      deleteAttachment,
      gotoConfigurate,
      searchRule,
      userColumns,
      userList,
      userPagnation,
      searchUser,
      publishVisible,
      handlePublish,
      downloadAttachment,
    };
  },
});
